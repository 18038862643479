import React, { useState } from "react";
import UserComponent from "./UserComponent";

export default function Users({ leads }) {
  const [type, settype] = useState("");
  const [plantype, setplantype] = useState("");
  const [status, setstatus] = useState("");
  const [searhName, setsearhName] = useState("");

  return (
    <div className="white_bg fw-7 sales_users_table" style={{ height: "100%" }}>
      <div className="d-flex mb-2 align-items-end">
        {/* <div className="d-flex">
          <p>
            <span
              style={{
                height: "20px",
                width: "30px",
                background: "green",
                color: "green",
                padding: "0px 2px",
              }}
            >
              ss
            </span>{" "}
            &nbsp; Active User (20/6)
          </p>
          &nbsp; &nbsp;
          <p>
            <span
              style={{
                height: "20px",
                width: "30px",
                background: "blue",
                color: "blue",
                padding: "0px 2px",
              }}
            >
              ss
            </span>
            &nbsp; Active User(8/2)
          </p>
          &nbsp; &nbsp;
          <p>
            <span
              style={{
                height: "20px",
                width: "30px",
                background: "red",
                color: "red",
                padding: "0px 2px",
              }}
            >
              ss
            </span>
            &nbsp; Inactive User Nurturing (0/1)
          </p>
          &nbsp; &nbsp;
          <p>
            <span
              style={{
                height: "20px",
                width: "30px",
                background: "yellow",
                color: "yellow",
                padding: "0px 2px",
              }}
            >
              ss
            </span>
            &nbsp; Logged In
          </p>
          &nbsp; &nbsp;
          <p>
            <span
              style={{
                height: "20px",
                width: "30px",
                background: "purple",
                color: "purple",
                padding: "0px 2px",
              }}
            >
              ss
            </span>
            &nbsp; Registered
          </p>
        </div> */}
        <p className="ms-3 fw-7 ms-auto">
          <span className="fw-6">
            {leads &&
              leads
                // .filter((lead) => {
                //   if (
                //     lead.type !== "fundmanager" &&
                //     lead.type !== "corporateuser" &&
                //     lead.type !== "academiauser"
                //   )
                //     return lead;
                // })
                .filter((lead) => {
                  if (type === "") return lead;
                  else if (lead.type === type) return lead;
                })
                .filter((lead) => {
                  if (plantype === "") return lead;
                  else if (lead.plan?.planName === plantype) return lead;
                })
                .filter((lead) => {
                  if (status === "") return lead;
                  else if (status === lead.userCategory) return lead;
                  // else if (
                  //   status === "Verified" &&
                  //   (lead.currentRegistrationStep === 10 || lead.name) &&
                  //   lead.isProfileVerified
                  // )
                  //   return lead;
                  // else if (
                  //   status === "Registered" &&
                  //   (lead.currentRegistrationStep === 10 || lead.name) &&
                  //   !lead.isProfileVerified
                  // )
                  //   return lead;
                  // else if (
                  //   status === "Sign Up" &&
                  //   lead.currentRegistrationStep !== 10 &&
                  //   !lead.isProfileVerified
                  // )
                  //   return lead;
                })
                .filter((lead) => {
                  if (searhName === "") return lead;
                  else if (
                    lead.name
                      ?.toLowerCase()
                      .includes(searhName.toLowerCase()) ||
                    lead.firstname
                      ?.toLowerCase()
                      .includes(searhName.toLowerCase()) ||
                    lead.lastname
                      ?.toLowerCase()
                      .includes(searhName.toLowerCase())
                  )
                    return lead;
                }).length}
          </span>{" "}
          Results
        </p>
        <div className="w_33 ms-3">
          <p className="mb-1">Name</p>
          <input
            placeholder="Search Name"
            className="w-100 h-40px"
            value={searhName}
            onChange={(e) => setsearhName(e.target.value)}
          />
        </div>
        <div className="ms-3">
          <p className="mb-1">Type</p>
          <select
            placeholder="Type"
            className="h-40px w-120px"
            value={type}
            onChange={(e) => settype(e.target.value)}
          >
            <option value="">All</option>
            <option value="startup">Startup</option>
            <option value="investor">Investor</option>
            <option value="fundcompany">Fund Company</option>
            <option value="corporate">Corporate</option>
            <option value="institute">Academia</option>
            <option value="student">Student</option>
          </select>
        </div>
        <div className="ms-3">
          <p className="mb-1" htmlFor="typeDropdown">
            Status
          </p>{" "}
          <select
            id="typeDropdown"
            className="h-40px w-120px"
            value={status}
            onChange={(e) => setstatus(e.target.value)}
          >
            <option value="">All</option>
            <option value="Active User (20/6)">Active User (20/6)</option>
            <option value="Active User(8/2)">Active User(8/2)</option>
            <option value="Inactive User Nurturing">
              Inactive User Nurturing
            </option>
            <option value="Logged In">Logged In</option>
            <option value="Registered">Registered</option>
          </select>
        </div>
        <div className="ms-3">
          <p className="mb-1" htmlFor="typeDropdown">
            Plan
          </p>{" "}
          <select
            id="typeDropdown"
            className="h-40px w-120px"
            value={plantype}
            onChange={(e) => setplantype(e.target.value)}
          >
            <option value="">All</option>
            <option value="Free">Free</option>
            <option value="Freemium">Freemium</option>
            <option value="Basic">Basic</option>
            <option value="Premium">Premium</option>
          </select>
        </div>
      </div>

      <div className="crm_table">
        <table style={{ width: "2400px" }}>
          <tr className="crm_table_row">
            <th>
              <div>
                <p className="fw-8">Registered</p>
                <p className="transform_none mb-0">
                  Registered but not logged in
                </p>
              </div>
            </th>
            <th>
              <div>
                <p className="fw-8">Logged In</p>
                <p className="transform_none mb-0">Logged In atleast once</p>
              </div>
            </th>
            <th>
              <div>
                <p className="fw-8">Active User(8/2)</p>
                <p className="transform_none mb-0">
                  Frequent users(atleast 8 times in 2 months)
                </p>
              </div>
            </th>
            <th>
              <div>
                <p className="fw-8">Active User (20/6)</p>
                <p className="transform_none mb-0">
                  Frequent users(atleast 20 times in 6 months)
                </p>
              </div>
            </th>
            <th>
              <div>
                <p className="fw-8">Inactive User </p>
                <p className="transform_none mb-0">Inactive for a months</p>
              </div>
            </th>
            <th>
              <div>
                <p className="fw-8">Deactivated</p>
                <p className="transform_none mb-0">User Deactivated Account</p>
              </div>
            </th>
            <th>
              <div>
                <p className="fw-8">Lost</p>
                <p className="mb-0 transform_none">
                  Registered but never logged in
                </p>
              </div>
            </th>
          </tr>
          <tr style={{ verticalAlign: "baseline" }}>
            <td>
              <div className="row m-0">
                {leads &&
                  leads
                    .filter((lead) => lead.userCategory === "Registered")
                    // .filter((lead) => {
                    //   if (
                    //     lead.type !== "fundmanager" &&
                    //     lead.type !== "corporateuser" &&
                    //     lead.type !== "academiauser"
                    //   )
                    //     return lead;
                    // })
                    .filter((lead) => {
                      if (type === "") return lead;
                      else if (lead.type === type) return lead;
                    })
                    .filter((lead) => {
                      if (plantype === "") return lead;
                      else if (lead.plan?.planName === plantype) return lead;
                    })
                    .filter((lead) => {
                      if (status === "") return lead;
                      else if (status === lead.userCategory) return lead;
                      // else if (
                      //   status === "Registered" &&
                      //   (lead.currentRegistrationStep === 10 || lead.name) &&
                      //   !lead.isProfileVerified
                      // )
                      //   return lead;
                      // else if (
                      //   status === "Sign Up" &&
                      //   lead.currentRegistrationStep !== 10 &&
                      //   !lead.isProfileVerified
                      // )
                      //   return lead;
                    })
                    .filter((lead) => {
                      if (searhName === "") return lead;
                      else if (
                        lead.name
                          ?.toLowerCase()
                          .includes(searhName.toLowerCase()) ||
                        lead.firstname
                          ?.toLowerCase()
                          .includes(searhName.toLowerCase()) ||
                        lead.lastname
                          ?.toLowerCase()
                          .includes(searhName.toLowerCase())
                      )
                        return lead;
                    })
                    .map((lead, i) => <UserComponent key={i} lead={lead} />)}
              </div>
            </td>
            <td>
              <div className="row m-0">
                {leads &&
                  leads
                    .filter((lead) => lead.userCategory === "Logged In")
                    // .filter((lead) => {
                    //   if (
                    //     lead.type !== "fundmanager" &&
                    //     lead.type !== "corporateuser" &&
                    //     lead.type !== "academiauser"
                    //   )
                    //     return lead;
                    // })
                    .filter((lead) => {
                      if (type === "") return lead;
                      else if (lead.type === type) return lead;
                    })
                    .filter((lead) => {
                      if (plantype === "") return lead;
                      else if (lead.plan?.planName === plantype) return lead;
                    })
                    .filter((lead) => {
                      if (status === "") return lead;
                      else if (status === lead.userCategory) return lead;
                      // else if (
                      //   status === "Registered" &&
                      //   (lead.currentRegistrationStep === 10 || lead.name) &&
                      //   !lead.isProfileVerified
                      // )
                      //   return lead;
                      // else if (
                      //   status === "Sign Up" &&
                      //   lead.currentRegistrationStep !== 10 &&
                      //   !lead.isProfileVerified
                      // )
                      //   return lead;
                    })
                    .filter((lead) => {
                      if (searhName === "") return lead;
                      else if (
                        lead.name
                          ?.toLowerCase()
                          .includes(searhName.toLowerCase()) ||
                        lead.firstname
                          ?.toLowerCase()
                          .includes(searhName.toLowerCase()) ||
                        lead.lastname
                          ?.toLowerCase()
                          .includes(searhName.toLowerCase())
                      )
                        return lead;
                    })
                    .map((lead, i) => <UserComponent key={i} lead={lead} />)}
              </div>
            </td>
            <td>
              <div className="row m-0">
                {leads &&
                  leads
                    .filter((lead) => lead.userCategory === "Active User(8/2)")
                    // .filter((lead) => {
                    //   if (
                    //     lead.type !== "fundmanager" &&
                    //     lead.type !== "corporateuser" &&
                    //     lead.type !== "academiauser"
                    //   )
                    //     return lead;
                    // })
                    .filter((lead) => {
                      if (type === "") return lead;
                      else if (lead.type === type) return lead;
                    })
                    .filter((lead) => {
                      if (plantype === "") return lead;
                      else if (lead.plan?.planName === plantype) return lead;
                    })
                    .filter((lead) => {
                      if (status === "") return lead;
                      else if (status === lead.userCategory) return lead;
                      // else if (
                      //   status === "Registered" &&
                      //   (lead.currentRegistrationStep === 10 || lead.name) &&
                      //   !lead.isProfileVerified
                      // )
                      //   return lead;
                      // else if (
                      //   status === "Sign Up" &&
                      //   lead.currentRegistrationStep !== 10 &&
                      //   !lead.isProfileVerified
                      // )
                      //   return lead;
                    })
                    .filter((lead) => {
                      if (searhName === "") return lead;
                      else if (
                        lead.name
                          ?.toLowerCase()
                          .includes(searhName.toLowerCase()) ||
                        lead.firstname
                          ?.toLowerCase()
                          .includes(searhName.toLowerCase()) ||
                        lead.lastname
                          ?.toLowerCase()
                          .includes(searhName.toLowerCase())
                      )
                        return lead;
                    })
                    .map((lead, i) => <UserComponent key={i} lead={lead} />)}
              </div>
            </td>
            <td>
              <div className="row m-0">
                {leads &&
                  leads
                    .filter(
                      (lead) => lead.userCategory === "Active User (20/6)"
                    )
                    // .filter((lead) => {
                    //   if (
                    //     lead.type !== "fundmanager" &&
                    //     lead.type !== "corporateuser" &&
                    //     lead.type !== "academiauser"
                    //   )
                    //     return lead;
                    // })
                    .filter((lead) => {
                      if (type === "") return lead;
                      else if (lead.type === type) return lead;
                    })
                    .filter((lead) => {
                      if (plantype === "") return lead;
                      else if (lead.plan?.planName === plantype) return lead;
                    })
                    .filter((lead) => {
                      if (status === "") return lead;
                      else if (status === lead.userCategory) return lead;
                      // else if (
                      //   status === "Registered" &&
                      //   (lead.currentRegistrationStep === 10 || lead.name) &&
                      //   !lead.isProfileVerified
                      // )
                      //   return lead;
                      // else if (
                      //   status === "Sign Up" &&
                      //   lead.currentRegistrationStep !== 10 &&
                      //   !lead.isProfileVerified
                      // )
                      //   return lead;
                    })
                    .filter((lead) => {
                      if (searhName === "") return lead;
                      else if (
                        lead.name
                          ?.toLowerCase()
                          .includes(searhName.toLowerCase()) ||
                        lead.firstname
                          ?.toLowerCase()
                          .includes(searhName.toLowerCase()) ||
                        lead.lastname
                          ?.toLowerCase()
                          .includes(searhName.toLowerCase())
                      )
                        return lead;
                    })
                    .map((lead, i) => <UserComponent key={i} lead={lead} />)}
              </div>
            </td>
            <td>
              <div className="row m-0">
                {leads &&
                  leads
                    .filter(
                      (lead) => lead.userCategory === "Inactive User Nurturing"
                    )
                    // .filter((lead) => {
                    //   if (
                    //     lead.type !== "fundmanager" &&
                    //     lead.type !== "corporateuser" &&
                    //     lead.type !== "academiauser"
                    //   )
                    //     return lead;
                    // })
                    .filter((lead) => {
                      if (type === "") return lead;
                      else if (lead.type === type) return lead;
                    })
                    .filter((lead) => {
                      if (plantype === "") return lead;
                      else if (lead.plan?.planName === plantype) return lead;
                    })
                    .filter((lead) => {
                      if (status === "") return lead;
                      else if (status === lead.userCategory) return lead;
                      // else if (
                      //   status === "Registered" &&
                      //   (lead.currentRegistrationStep === 10 || lead.name) &&
                      //   !lead.isProfileVerified
                      // )
                      //   return lead;
                      // else if (
                      //   status === "Sign Up" &&
                      //   lead.currentRegistrationStep !== 10 &&
                      //   !lead.isProfileVerified
                      // )
                      //   return lead;
                    })
                    .filter((lead) => {
                      if (searhName === "") return lead;
                      else if (
                        lead.name
                          ?.toLowerCase()
                          .includes(searhName.toLowerCase()) ||
                        lead.firstname
                          ?.toLowerCase()
                          .includes(searhName.toLowerCase()) ||
                        lead.lastname
                          ?.toLowerCase()
                          .includes(searhName.toLowerCase())
                      )
                        return lead;
                    })
                    .map((lead, i) => <UserComponent key={i} lead={lead} />)}
              </div>
            </td>
            <td>
              <div className="row m-0">
                {leads &&
                  leads
                    .filter((lead) => lead.userCategory === "Deactivated")
                    // .filter((lead) => {
                    //   if (
                    //     lead.type !== "fundmanager" &&
                    //     lead.type !== "corporateuser" &&
                    //     lead.type !== "academiauser"
                    //   )
                    //     return lead;
                    // })
                    .filter((lead) => {
                      if (type === "") return lead;
                      else if (lead.type === type) return lead;
                    })
                    .filter((lead) => {
                      if (plantype === "") return lead;
                      else if (lead.plan?.planName === plantype) return lead;
                    })
                    .filter((lead) => {
                      if (status === "") return lead;
                      else if (status === lead.userCategory) return lead;
                      // else if (
                      //   status === "Registered" &&
                      //   (lead.currentRegistrationStep === 10 || lead.name) &&
                      //   !lead.isProfileVerified
                      // )
                      //   return lead;
                      // else if (
                      //   status === "Sign Up" &&
                      //   lead.currentRegistrationStep !== 10 &&
                      //   !lead.isProfileVerified
                      // )
                      //   return lead;
                    })
                    .filter((lead) => {
                      if (searhName === "") return lead;
                      else if (
                        lead.name
                          ?.toLowerCase()
                          .includes(searhName.toLowerCase()) ||
                        lead.firstname
                          ?.toLowerCase()
                          .includes(searhName.toLowerCase()) ||
                        lead.lastname
                          ?.toLowerCase()
                          .includes(searhName.toLowerCase())
                      )
                        return lead;
                    })
                    .map((lead, i) => <UserComponent key={i} lead={lead} />)}
              </div>
            </td>
            <td>
              <div className="row m-0">
                {leads &&
                  leads
                    .filter((lead) => lead.userCategory === "Lost")
                    // .filter((lead) => {
                    //   if (
                    //     lead.type !== "fundmanager" &&
                    //     lead.type !== "corporateuser" &&
                    //     lead.type !== "academiauser"
                    //   )
                    //     return lead;
                    // })
                    .filter((lead) => {
                      if (type === "") return lead;
                      else if (lead.type === type) return lead;
                    })
                    .filter((lead) => {
                      if (plantype === "") return lead;
                      else if (lead.plan?.planName === plantype) return lead;
                    })
                    .filter((lead) => {
                      if (status === "") return lead;
                      else if (status === lead.userCategory) return lead;
                      // else if (
                      //   status === "Registered" &&
                      //   (lead.currentRegistrationStep === 10 || lead.name) &&
                      //   !lead.isProfileVerified
                      // )
                      //   return lead;
                      // else if (
                      //   status === "Sign Up" &&
                      //   lead.currentRegistrationStep !== 10 &&
                      //   !lead.isProfileVerified
                      // )
                      //   return lead;
                    })
                    .filter((lead) => {
                      if (searhName === "") return lead;
                      else if (
                        lead.name
                          ?.toLowerCase()
                          .includes(searhName.toLowerCase()) ||
                        lead.firstname
                          ?.toLowerCase()
                          .includes(searhName.toLowerCase()) ||
                        lead.lastname
                          ?.toLowerCase()
                          .includes(searhName.toLowerCase())
                      )
                        return lead;
                    })
                    .map((lead, i) => <UserComponent key={i} lead={lead} />)}
              </div>
            </td>
          </tr>
        </table>
      </div>
    </div>
  );
}

import React, { useEffect, useState } from "react";
import { Avatar, Badge, Button, Tooltip } from "@mui/material";
import { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import localNumber from "../../../../Utils/localnumber";

const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#f5f5f9",
    color: "rgba(0, 0, 0, 0.87)",
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: "1px solid #dadde9",
  },
}));

const UserComponent = ({ lead, getLeads, setValue }) => {
  const [status, setstatus] = useState(lead.status);
  const [Priority, setPriority] = useState(lead.Priority);
  const [responsibility, setResponsibility] = useState(lead.responsibility);

  const navigate = useNavigate();

  const updateDetails = async (i_status, i_stared, responsibility) => {
    await axios({
      method: "post",
      url: "/api/sale/add",
      data: {
        id: lead._id,
        status: i_status,
        Priority: i_stared,
        responsibility,
      },
    }).then(() => {
      getLeads();
      setstatus(i_status);
      setPriority(i_stared);
      setResponsibility(responsibility);
    });
  };

  useEffect(() => {
    if (lead) {
      setstatus(lead.status);
      setPriority(lead.Priority);
    }
  }, [lead]);
  return (
    <div className="col-6 p-1">
      <HtmlTooltip
        title={
          <>
            <div className="d-flex justify-content-between align-items-start">
              <div>
                <p className="bold mt-6">
                  {lead.name}({lead.userType})
                </p>
                <p className="bold mt-6">Contact Person: {lead.contactName}</p>
                <p className="bold mt-6">
                  Revenue:{" "}
                  {lead.expectedRevenue
                    ? localNumber(lead.expectedRevenue)
                    : "NA"}
                </p>
                <p className="bold mt-6">
                  Date :{" "}
                  {lead.date
                    ? new Date(lead.date).toLocaleDateString("en-GB")
                    : "NA"}
                </p>
              </div>
              <Button
                size="small"
                onClick={() => {
                  navigate(`/admin/sales?id=${lead._id}`);
                  setValue(2);
                }}
              >
                Edit
              </Button>
            </div>
            <label>Change Status</label>
            <select
              onChange={(e) =>
                updateDetails(e.target.value, Priority, responsibility)
              }
              value={status}
              className="mb-3 w-100"
            >
              <option value={""}>Change Status</option>
              <option value={"New Lead"}>New Lead</option> {/* Contacted  */}
              <option value={"1st Contact"}>1st Contact</option>
              <option value={"Initial Meet"}>Initial Meet</option>{" "}
              <option value={"Test Account"}>Test Account</option>
              <option value={"Lost(Test User)"}>Lost(Test User)</option>
              <option value={"Offer Placed"}>Offer Placed</option>
              <option value={"Offer Accepted"}>Offer Accepted </option>
              <option value={"Invoice Sent"}>Invoice Sent</option>
              <option value={"Money Received"}>Money Received</option>
              <option value={"Customer Nurturing"}>Customer Nurturing</option>
              <option value={"Subscription Expired"}>
                Subscription Expired
              </option>
              <option value={"Declined"}>Declined</option>
              <option value={"Lost"}>Lost</option>
              <option value={"No Potential"}>No Potential</option>
            </select>
            <br />
            <label>Change Responsibility</label>
            <select
              onChange={(e) => updateDetails(status, Priority, e.target.value)}
              value={responsibility}
              className="mb-3 w-100"
            >
              <option value="Susanne Hahn">Susanne Hahn</option>
              <option value="Aparna Veer">Aparna Veer</option>
              <option value="Daniel">Daniel Bode</option>
              <option value="Simon">Simon Zieker</option>
              <option value="Volker">Volker Gesman</option>
              <option value="Fostec Ventures">Fostec Ventures</option>
              <option value="Nikolai Ivansson">Nikolai Ivansson</option>
              <option value="Marc-Oliver Nandy">Marc-Oliver Nandy</option>
              <option value={"Kurt Knoesslsdorfer"}>Kurt Knoesslsdorfer</option>
              <option value={"Raj Singh"}>Raj Singh</option>
              <option value={"Thomas Kropf"}>Thomas Kropf</option>
              <option value={"Winfried Hagenhoff"}>Winfried Hagenhoff</option>
              <option value={"⁠Florian Kirschner"}>⁠Florian Kirschner</option>
              <option value="Rahul Sawlani">Rahul Sawlani</option>

              <option value={"Platform User"}>Platform User</option>
              <option value={"None"}>None</option>
            </select>
            <label>
              Priority{" "}
              {lead.Priority ? <span>&#9733;</span> : <span>&#9734;</span>}
            </label>
            <select
              onChange={(e) =>
                updateDetails(status, e.target.value, responsibility)
              }
              value={Priority}
              className="mb-3 w-100"
            >
              <option value="High">High</option>
              <option value="Medium">Medium</option>
              <option value="Low">Low</option>
            </select>
            {lead.note && (
              <p>
                {" "}
                <span className="bold">NOTE:</span> {lead.note}
              </p>
            )}
          </>
        }
        placement="top"
      >
        <div
          className={`${
            lead.isInterestedInIncubationTool ? "blueBorder " : ""
          }`}
        >
          <div
            className={`pointer ${lead.isAssociation ? "redborder " : ""}`}
            onClick={() => {
              navigate(`/admin/sales?id=${lead._id}`);
              setValue(2);
            }}
          >
            <Badge
              badgeContent={
                <p className="m-0">
                  {lead.Priority ? <span>{lead.Priority[0]}</span> : ""}
                </p>
              }
              color={
                lead.offerType === "Platform"
                  ? "primary"
                  : lead.offerType === "Services"
                  ? "warning"
                  : "secondary"
              }
              // style={{ backgroundColor: "red" }}
            >
              <Avatar
                src={lead.logo}
                sx={{
                  width: 40,
                  height: 40,
                  padding: 0,
                }}
              >
                {lead.name[0] && lead.name[0]}
              </Avatar>
            </Badge>
            <p style={{ fontSize: "14px" }}>{lead.name.substring(0, 15)}</p>
          </div>
        </div>
        {/* </Badge> */}
      </HtmlTooltip>
    </div>
  );
};

export default UserComponent;
